// var env = process.env.REACT_APP_ENV;//'production'
export var env = "development";
export const Constant = {
  apiURl:
    env === "development"
      ? "http://3.129.171.117:3000/v1"
      : "http://192.168.122.1:8888/v1",
  mediaUrl:
    env === "development"
      ? "http://3.129.171.117:3000/media/"
      : "http://192.168.122.1:8888/media/",
  socketUrl:
    env === "development"
      ? "http://3.129.171.117:5000/"
      : "http://localhost:5000/",
};
export const reminderNameList = [
  {
    label: "First Order in order basket",
    value: "First Order in order basket",
  },
  {
    label: "Order basket complete",
    value: "Order basket complete",
  },
  {
    label: "Quotation by suppliers required",
    value: "Quotation by suppliers required",
  },
  {
    label: "Captain sends order / Supplier receives order",
    value: "Captain sends order / Supplier receives order",
  },
  {
    label: "Upcoming delivery",
    value: "Upcoming delivery",
  },
  {
    label: "Order delivery",
    value: "Order delivery",
  },
];
export const inventory = "Inventory";
